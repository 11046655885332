import React, { Fragment } from "react";
import axios from "axios";
import Footer from "./Footer"
import ReactModal from 'react-modal';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { isAwaitExpression } from "typescript";

let data

const orientations = ["Landscape", "Portrait"];
const maxWidths = {
    "Landscape": 5,
    "Portrait": 5
};
const maxHeights = {
    "Landscape": 5,
    "Portrait": 3
};

const resolutions = ["HD", "4K"];
const contents = ["Images", "Videos"];

const wallImages = ["1.jpg", "2.jpeg", "3.jpg"];
const wallVideos = ["80s_cube_rv01.mp4", "Toulouse GOLD.mp4", "Toulouse v6 FINAL with logos.mp4"];

let bestMatchedDisplay = null;
let matchedBezels = [];

let bestMatchedBrightsign = null;

class ScaleCalculator extends React.Component {
    constructor() {
        super();

        this.state = {
            orientation: "Landscape",
            width: 3,
            height: 3,
            bezels: -1,
            resolution: "HD",
            content: "Images",
            installation: false,
            windowWidth: 1200,
            wallImage: "/assets/configurator/images/" + wallImages[Math.floor(Math.random() * 3)],
            wallVideo: "/assets/configurator/videos/" + wallVideos[Math.floor(Math.random() * 3)],
            pdfProgress: -1,
            loading: true
        };

        this.displays = [];
        this.brightsigns = [];
        this.hardwareList = {};
        this.email = "";

        this.preview = React.createRef();

        this.selectOrientation = this.selectOrientation.bind(this);
        this.selectWidth = this.selectWidth.bind(this);
        this.selectHeight = this.selectHeight.bind(this);
        this.selectBezels = this.selectBezels.bind(this);
        this.selectResolution = this.selectResolution.bind(this);
        this.selectContent = this.selectContent.bind(this);
        this.selectInstallation = this.selectInstallation.bind(this);

        this.setInitialState = this.setInitialState.bind(this);
        this.filterHardware = this.filterHardware.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getData()
        setTimeout(() =>{
            this.setInitialState();
            window.addEventListener("resize", this.updateDimensions.bind(this));
            this.updateDimensions();
        }, 200)
        
    }

    getData() {
        axios.get('https://signsync.co.nz/hardware.json')
             .then(function(response) {
                 data = response.data
                 console.log(data);
             })
             .catch(function (error) {
                 // handle error
                 console.log(error);
             })
     }

    updateDimensions() {
        this.setState({
            windowWidth: window.innerWidth
        });
    }

    handleChange(event) {
        this.email = document.getElementById("pdfEmail").value;
    }

    handleSubmit(event) {
        event.preventDefault();
        // if (this.email === "") {
        //     alert("Please enter your email address.");
        //     return;
        // }

        // var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
        // if (!reeamil.test(this.email)) {
        //     alert("Please enter a valid email address.");
        //     return;
        // }

        let initialX = window.scrollX;
        let initialY = window.scrollY;
        let initialContent = this.state.content;
        window.scrollTo(0, 0);

        this.setState({
            content: "Images",
            pdfProgress: "1/3"
        }, async function () {
            let pageWidth = 297;
            let pageHeight = 210;

            let lineWidth = 1;
            let lineIndent = 5;

            // make the pdf here
            const doc = new jsPDF({
                orientation: 'l',
                compress: true
            });

            doc.setDrawColor("#06144d");
            doc.setLineWidth(`${lineWidth}`);
            doc.rect(lineWidth / 2, lineWidth / 2, pageWidth - lineWidth, pageHeight - lineWidth, "S");
            doc.rect(
                (lineWidth + lineIndent) / 2,
                (lineWidth + lineIndent) / 2,
                pageWidth - lineWidth - lineIndent,
                pageHeight - lineWidth - lineIndent,
                "S"
            );

            let logoWidth = 100;
            let logoHeight = 0.316 * logoWidth;
            let img = new Image();
            img.src = "assets/SignSync-horizontal.png"
            doc.addImage(img, 'png', 8, 10, logoWidth, logoHeight);

            this.setState({
                pdfProgress: "2/3"
            });

            await html2canvas(document.querySelector(".configurator"), {
                removeContainer: true,
                scale: 2
            }).then(canvas => {
                img.src = canvas.toDataURL();
            })

            this.setState({
                pdfProgress: "3/3"
            });

            let imageIndent = 10;

            let imageWidth = pageWidth - imageIndent * 2;
            let imageHeight = pageWidth * 447.4 / 1220;
            let x = imageIndent;
            let y = (pageHeight - imageHeight) / 2;
            doc.addImage(img, 'png', x, y, imageWidth, imageHeight);

            await html2canvas(document.querySelector(".footer .justify-content-center"), {
                scale: 2
            }).then(canvas => {
                img.src = canvas.toDataURL();
            });

            let configHeight = imageHeight;

            imageWidth = pageWidth - imageIndent * 2;
            imageHeight = 0.08529 * imageWidth;
            x = imageIndent;
            y = 171.5;
            doc.addImage(img, 'png', x, y, imageWidth, imageHeight);

            doc.save('signsync-config.pdf');

            // $.ajax({
            //     type: "POST",
            //     url: "https://u1mljlewli.execute-api.ap-southeast-2.amazonaws.com/prod/configurator",
            //     dataType: "json",
            //     crossDomain: "true",
            //     contentType: "application/json; charset=utf-8",
            //     data: JSON.stringify({
            //         email: this.email
            //     }),
            //     success: function () {
            //         alert("Success");
            //     },
            //     error: function () {
            //         alert(`Error`);
            //     }
            // });

            this.resetForm();

            // reset page
            window.scrollTo(initialX, initialY);
            this.setState({
                content: initialContent,
                pdfProgress: -1
            });
        });
    }

    resetForm() {
        document.getElementById("pdfForm").reset();
    }

    setInitialState() {
    

        bestMatchedDisplay = data.displays[0];
        bestMatchedBrightsign = data.brightsigns[0];

        this.setState({
            resolution: bestMatchedDisplay.resolution,
            content: bestMatchedBrightsign.content[bestMatchedBrightsign.content.length - 1]
        }, function () {
            this.filterHardware();
        });
    }

    selectOrientation(changeEvent) {
        this.setState({
            orientation: changeEvent.target.value
        }, function () {
            this.setState({ height: Math.min(this.state.height, maxHeights[this.state.orientation]) })
            this.filterHardware();
        });
    }

    selectWidth(changeEvent) {
        this.setState({
            width: parseInt(changeEvent.target.value)
        });
    }

    selectHeight(changeEvent) {
        this.setState({
            height: parseInt(changeEvent.target.value)
        });
    }

    selectBezels(changeEvent) {
        this.setState({
            bezels: parseFloat(changeEvent.target.value)
        }, function () {
            this.filterHardware();
        });
    }

    selectResolution(changeEvent) {
        this.setState({
            resolution: changeEvent.target.value
        }, function () {
            this.filterHardware();
        });
    }

    selectContent(changeEvent) {
        this.setState({
            content: changeEvent.target.value
        });
    }

    selectInstallation(changeEvent) {
        this.setState({
            installation: !this.state.installation
        })
    }

    filterHardware() {
        // filter displays by resolution
        let matchedDisplays = [];
        for (let displayIndex in data.displays) {
            const display = data.displays[displayIndex];

            if (display.resolution !== this.state.resolution)
                continue;

            matchedDisplays.push(display);
        }

        matchedDisplays.sort(function (a, b) { return a.price > b.price });

        // filter bezels by resolution
        matchedBezels = [];
        for (let displayIndex in matchedDisplays) {
            const display = matchedDisplays[displayIndex];

            if (matchedBezels.includes(display.bezels))
                continue;

            matchedBezels.push(display.bezels);
        }

        matchedBezels.sort(function (a, b) { return a < b });

        // refilter displays by bezels
        let rematchedDisplays = [];
        for (let displayIndex in matchedDisplays) {
            const display = matchedDisplays[displayIndex];

            if (display.bezels !== this.state.bezels)
                continue;

            rematchedDisplays.push(display);
        }

        rematchedDisplays.sort(function (a, b) { return a.price < b.price; });

        if (rematchedDisplays.length > 0) {
            bestMatchedDisplay = rematchedDisplays[0];
        } else if (!matchedBezels.includes(this.state.bezels)) {
            this.setState({
                bezels: matchedBezels[0]
            }, function () {
                this.filterHardware();
            });
        }

        let filteredBrightsigns = [];
        for (const brightsignIndex in data.brightsigns) {
            const brightsign = data.brightsigns[brightsignIndex];

            if (!brightsign.orientations.includes(this.state.orientation))
                continue;

            if (!brightsign.resolutions.includes(this.state.resolution))
                continue;

            filteredBrightsigns.push(brightsign);
        }

        filteredBrightsigns.sort(function (a, b) { return a.price > b.price });

        if (filteredBrightsigns.length > 0)
            bestMatchedBrightsign = filteredBrightsigns[0];

        this.forceUpdate();
    }

    UNSAFE_componentWillMount() {
        ReactModal.setAppElement('body');
        window.scrollTo(0, 0)
    }

    render() {
        let scale = 740 / 60;
        let displayWidth = 8;
        let displayHeight = 4.5;

        if (this.state.orientation === "Portrait") {
            let temp = displayWidth;
            displayWidth = displayHeight;
            displayHeight = temp;
        }

        let rawWidth = this.state.width * displayWidth;
        let rawHeight = this.state.height * displayHeight;

        let mediaWidth = rawWidth * scale + "px";
        let mediaHeight = rawHeight * scale + "px";

        let mediaTop = (18 - rawHeight / 2) * scale + "px";
        let mediaLeft = (26 - rawWidth / 2) * scale + "px";
        let mediaRight = (26 + rawWidth / 2) * scale + "px";

        let widthTop = (16 - rawHeight / 2) * scale - 10 + "px";

        let displayStyle = {
            width: `${displayWidth * scale}px`,
            height: `${displayHeight * scale}px`, "border": `solid 1px black`
        };

        let physicalWidth = "?m";
        let physicalHeight = "?m";

        if (bestMatchedDisplay != null && this.state.orientation == "Landscape") {
            physicalWidth = Math.ceil(bestMatchedDisplay.width * this.state.width / 100) / 10 + "m";
            physicalHeight = Math.ceil(bestMatchedDisplay.height * this.state.height / 100) / 10 + "m";
        } else if (bestMatchedDisplay != null) {
            physicalWidth = Math.ceil(bestMatchedDisplay.height * this.state.width / 100) / 10 + "m";
            physicalHeight = Math.ceil(bestMatchedDisplay.width * this.state.height / 100) / 10 + "m";
        }

        // bezel media
        let bezelFactor = 4;

        let bezelRawWidth = Math.max(this.state.width, 2) * displayWidth * bezelFactor;
        let bezelRawHeight = Math.max(this.state.height, 2) * displayHeight * bezelFactor;

        let bezelMediaWidth = bezelRawWidth * scale + "px";
        let bezelMediaHeight = bezelRawHeight * scale + "px";

        let bezelMediaTop = (7.5 - bezelFactor * displayHeight) * scale + "px";
        let bezelMediaLeft = (7.5 - bezelFactor * displayWidth) * scale + "px";

        let bezelDisplayStyle = {
            "borderBottom": `solid ${this.state.bezels * bezelFactor * 0.013 * scale}px black`,
            "borderRight": `solid ${this.state.bezels * bezelFactor * 0.013 * scale}px black`
        };

        let cells = []
        for (let i = 0; i < this.state.width; i++) {
            cells.push(<div key={"cell " + i} className="display-example" style={displayStyle}></div>);
        }

        let elements = [];
        for (let i = 0; i < this.state.height; i++) {
            elements.push(<div key={"row " + i} className="display-row">{cells}</div>);
        }

        let widthButtons = [];
        for (let i = 1; i <= maxWidths[this.state.orientation]; i++) {
            widthButtons.push([<input key={"widthButton " + i} type="radio" name="width" checked={this.state.width === i} value={i.toString()} onChange={this.selectWidth} />, i]);
        }

        let heightButtons = [];
        for (let i = 1; i <= maxHeights[this.state.orientation]; i++) {
            if (i > 3 && this.state.orientation == "Portrait")
                break;

            heightButtons.push([<input key={"heightButton " + i} type="radio" name="height" checked={this.state.height === i} value={i.toString()} onChange={this.selectHeight} />, i]);
        }

        let hardware = [];
        let displayCount = this.state.width * this.state.height;

        if (displayCount === 1) {
            this.hardwareList.displays = <div key="displayHardware" className="outputs">1 × 55" 4K Display<br />Brightness: {data.singleDisplay.brightness} nits</div>;
            this.hardwareList.mounting = `1 × Mounting Hardware`;
        } else if (bestMatchedDisplay !== null) {
            this.hardwareList.displays = <div key="displayHardware" className="outputs">{displayCount} × 55" HD Display<br />Brightness: {bestMatchedDisplay.brightness} nits</div>;
            this.hardwareList.mounting = `${displayCount} × Mounting Hardware`;
        } else {
            this.hardwareList.displays = "Cannot match display.";
        }

        if (bestMatchedBrightsign !== null) {
            this.hardwareList.brightSigns = `${displayCount} × ${bestMatchedBrightsign.model}`;
        } else {
            this.hardwareList.brightSigns = "Cannot match BrightSign.";
        }

        if (this.state.installation) {
            this.hardwareList.installation = "Installed by Toulouse";
        } else {
            this.hardwareList.installation = "No Installation Included";
        }

        if (displayCount === 1) {
            let styleObject = {
                color: "gray",
                "font-size": "14px"
            };

            let estimate = data.singleDisplay.priceIncludingMount;
            estimate += this.state.installation ? (displayCount * 190 + 285) : 0;
            estimate = Math.ceil(estimate / 100) * 100;
            this.hardwareList.estimate = <>
                <span>{Number(estimate).toLocaleString()}</span>
                <br />
                <span style={styleObject}>Price reflects 24/7 entry-level monitor</span>
            </>;
        } else if (bestMatchedDisplay !== null && bestMatchedBrightsign !== null) {
            console.log (hardware);
            console.log (typeof data.mountingPrice);
            let estimate = 0;
            estimate += displayCount * bestMatchedDisplay.price; // display price
            console.log(displayCount, bestMatchedDisplay.price);
            estimate += displayCount * bestMatchedBrightsign.price; // brightsign price
            console.log(displayCount, bestMatchedBrightsign.price);
            estimate += displayCount * data.mountingPrice; // mounting price
            console.log(displayCount, data.mountingPrice);
            estimate += this.state.installation ? (displayCount * 190 + 285) : 0;
            console.log(estimate);
            estimate = Math.ceil(estimate / 100) * 100;
            console.log(estimate);
            this.hardwareList.estimate = Number(estimate).toLocaleString();
        } else {
            this.hardwareList.estimate = "Cannot calculate estimate.";
        }

        hardware.push(<div key='heading' className='heading'>Displays</div>);
        hardware.push(this.hardwareList.displays);
        hardware.push(<hr key="hr0" />);

        hardware.push(<div key='bsheading' className='heading'>BrightSigns</div>);
        hardware.push(<div key="brightSignHardware" className="outputs">{this.hardwareList.brightSigns}</div>);
        hardware.push(<hr key="hr1" />);

        hardware.push(<div key='mheading' className='heading'>Mounting</div>);
        hardware.push(<div key="mountingHardware" className="outputs">{this.hardwareList.mounting}</div>);
        hardware.push(<hr key="hr2" />);

        hardware.push(<div key='iheading' className='heading'>Installation</div>);
        hardware.push(<div key="installation" className="outputs">{this.hardwareList.installation}</div>);
        hardware.push(<hr key="hr3" />);

        hardware.push(<div key='eheading' className='heading'>Estimate</div>);
        hardware.push(<div key="price" className="outputs">${this.hardwareList.estimate}</div>);
        hardware.push(<hr key="hr4" />);

        hardware.push(<div key="disclosure" className="outputs"><p className="configuratorWarning">Intended as an estimate only.</p></div>);

        let bezelSelector = null;
        if (displayCount > 1) {
            bezelSelector =
                <div className='wall-pane-section'>
                    <div className='heading'>Edge-to-edge bezels</div>
                    <div className='inputs'>
                        {matchedBezels.map(b => ([<input key={b} type="radio" name="bezels" checked={this.state.bezels === b} value={b} onChange={this.selectBezels} />, `${b}mm`, <br key={"br" + b} />]))}
                    </div>
                </div>
        } else {
            bezelSelector =
                <div className='wall-pane-section'>
                    <div className='heading'>{data.singleDisplay.bezels}mm standard bezels</div>
                </div>
        }

        if (this.state.windowWidth < 1200) {
            return (
                <Fragment>
                    <h2 className="text-center" id="caseTitle" >Pricing</h2>
                    <h3 style={{ "textAlign": "center", "color": "#ef3e31" }}>
                        Screen size not supported. Please enlarge browser window or use a larger device.
                    </h3>
                    <Footer />
                </Fragment>
            );
        }

        return (
            <Fragment>
                <h2 className="text-center" id="caseTitle" >Pricing</h2>

                <div className="configurator">
                    <div className="maxWidth">
                        <div className='wall-pane'>
                            <div className='wall-pane-section'>
                                <div className='heading'>Orientation</div>
                                {orientations.map(o => ([<input key={o} type="radio" name="orientation" checked={this.state.orientation === o} value={o} onChange={this.selectOrientation} />, o]))}
                            </div>
                            <hr />
                            <div className='wall-pane-section'>
                                <div className='heading'>Width</div>
                                <div className='inputs'>
                                    {widthButtons}
                                </div>
                            </div>
                            <hr />
                            <div className='wall-pane-section'>
                                <div className='heading'>Height</div>
                                <div className='inputs'>
                                    {heightButtons}
                                </div>
                            </div>
                            <hr />
                            {bezelSelector}
                            <hr />
                            <div className='wall-pane-section'>
                                <div className='heading'>Content</div>
                                <div className='inputs'>
                                    {contents.map(c => ([<input key={c} type="radio" name="content" checked={this.state.content === c} value={c} onChange={this.selectContent} />, c]))}
                                </div>
                            </div>
                            <hr />
                            <div className='wall-pane-section'>
                                <div className='inputs'>
                                    <input key="installation" type="checkbox" name="installation" checked={this.state.installation} value={this.state.installation ? "Installation" : "No installation"} onChange={this.selectInstallation} />Installation
                                </div>
                            </div>
                            {/* <div className='wall-pane-section'>
                                <div className='heading'>Resolution</div>
                                <div className='inputs'>
                                    {resolutions.map(r => ([<input type="radio" name="resolution" checked={this.state.resolution === r} value={r} onClick={this.selectResolution} />, r]))}
                                </div>
                            </div>
                            <hr /> */}
                            {/* <div className='wall-pane-section'>
                            <div className='heading'>Environment</div>
                            <div className='inputs'>
                                {contents.map(c => ([<input type="radio" name="content" checked={this.state.content === c} value={c} onClick={this.selectContent} />, c]))}
                            </div>
                        </div> */}
                        </div>

                        <div className="wall-preview" ref={this.preview}>
                            <div className="wall-image" style={{ backgroundImage: "url(/assets/blank-wall.jpg)" }}>
                                <div className="wall-displays" style={{ width: mediaWidth, height: mediaHeight, top: mediaTop, left: mediaLeft }}>
                                    {this.state.content === "Images" ?
                                        <img className="display-image" src={this.state.wallImage} alt="" style={{ width: mediaWidth, height: mediaHeight }}></img> :
                                        <video className="display-video" src={this.state.wallVideo} style={{ width: mediaWidth, height: mediaHeight }} muted loop autoPlay="autoPlay"></video>}
                                    <div className="display-grid" style={{ width: mediaWidth, height: mediaHeight }}>
                                        {elements}
                                    </div>
                                </div>

                                <div className="wallWidth" style={{ top: widthTop, left: mediaLeft, width: mediaWidth }}>
                                    {physicalWidth}
                                </div>

                                <div className="wallHeight" style={{ top: mediaTop, left: mediaRight, height: mediaHeight }}>
                                    {physicalHeight}
                                </div>

                                <div className="wall-bezels">
                                    {this.state.content === "Images" ?
                                        <img className="display-image" src={this.state.wallImage} alt="" style={{ top: bezelMediaTop, left: bezelMediaLeft, width: bezelMediaWidth, height: bezelMediaHeight }}></img> :
                                        <video className="display-video" src={this.state.wallVideo} style={{ top: bezelMediaTop, left: bezelMediaLeft, width: bezelMediaWidth, height: bezelMediaHeight }} muted loop autoPlay="autoPlay"></video>}
                                    <div className="bezel-grid" style={{ position: "relative", top: `-${scale}px`, left: `-${scale}px`, width: `${17 * scale}px`, height: `${17 * scale}px` }}>
                                        <div key={"bezel-row 0"} className="bezel-row" style={{ width: `${17 * scale}px`, height: `${8.5 * scale}px` }}>
                                            <div key={"bezel-cell 00"} className="bezel-example" style={bezelDisplayStyle}></div>
                                            <div key={"bezel-cell 01"} className="bezel-example" style={bezelDisplayStyle}></div>
                                        </div>
                                        <div key={"bezel-row 1"} className="bezel-row" style={{ width: `${17 * scale}px`, height: `${8.5 * scale}px` }}>
                                            <div key={"bezel-cell 10"} className="bezel-example" style={bezelDisplayStyle}></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="wallDisclosure">
                                    <p className="configuratorWarning">Depicted bezels are a guide only</p>
                                </div>
                            </div>
                        </div>

                        <div className='wall-pane'>
                            <div className='wall-pane-section'>
                                {hardware}
                            </div>
                        </div>

                        <div style={{ clear: "both" }}></div>
                    </div >
                </div>
                <div className="configForm">
                    <form id="pdfForm" onSubmit={this.handleSubmit} className="text-center">
                        {/* <input type="text" id="pdfEmail" value={this.state.value} placeholder="Email" onChange={this.handleChange} /> */}
                        <button id="emailPDF" className="standardButton" type="submit">{this.state.pdfProgress == -1 ? "Download .pdf" : "Please wait ... " + this.state.pdfProgress}</button>
                    </form>
                </div>
                <Footer />
            </Fragment >
        );
    };
}

export default ScaleCalculator;

